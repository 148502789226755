import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Welcome to Istanbul Grill.
      </Typography>
      <Typography variant="body1" paragraph>
        We are a family run Turkish kebab shop providing takeaway and delivery
        service. We've been sourcing only ingredients we would like to eat
        ourselves for almost eleven years.
      </Typography>
      <Typography variant="body1" paragraph>
        Our local kebab shop offers traditional Turkish kebabs alongside a
        changing menu of grilled specials.
      </Typography>
      <Typography variant="body1" paragraph>
        For a decade we've practised and innovated on our recipes and systems,
        and this refinement and experience can be enjoyed by you with every
        order.
      </Typography>
      <Typography variant="body1" paragraph>
        Our mission is to tempt everyone who loves good food by using basic
        ingredients of a Turkish kebab shop in a surprising way.
      </Typography>
      <Typography variant="body1" paragraph>
        Terms and Conditions: Prices are subject to change without any notice.
        All the meats on the menu are halal. Management reserves the right to
        refuse to serve or deliver. Photography is for illustrative purposes
        only and /or its packaging may vary from photos. Some food may contain
        small bones and cartilages. If in doubt about allergens please contact
        and ask a member of staff.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
